import {
  Box,
  FormControlLabel,
  LinearProgress,
  Switch,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { ImageSelect } from "../fields/ImageSelect";
import ListDnd from "./ListDnd";
import SelectCategories from "./SelectCategories";

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      marginLeft: "10px",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    selectInput: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",
    },
    inputLabel: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
    },
    textField: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",
    },
    selectNotes: {
      flex: 1,
    },
  })
);

const GrillaFormWithoutImg = ({
  handleCallback,
  componentData,
  listCategories,
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [listNotes, setListNotes] = useState();
  const [title, setTitle] = useState(componentData.title ?? "");

  const handleChangeField = (value, field) => {
    componentData[field] = value;
    handleCallback(componentData);
  };

  const handleChangeListNote = (list) => {
    if (list && list.length > 0) {
      componentData.notes = [];
      list.forEach((elem) => {
        componentData.notes.push(elem.id ? elem : "");
      });
      handleCallback(componentData);
    }
  };

  useEffect(() => {
    const list = [];
    if (componentData.notes) {
      componentData.notes.forEach((elem) => {
        list.push(elem ? elem : {});
      });
    }
    setListNotes(list);
  }, [componentData.notes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeOutId = setTimeout(
      () => title !== componentData.title && handleChangeField(title, "title"),
      800
    );
    return () => clearTimeout(timeOutId);
  }, [title]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    componentData && (
      <Box width='100%'>
        <Box display='flex' justifyContent='space-between'>
          <Box display='flex' flexDirection='column'>
            <TextField
              id='component-title'
              label={translate('components.title_section')}
              margin='dense'
              variant='filled'
              className={classes.textField}
              value={title || ""}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Box width='150px' mb='.9em'>
              <Typography variant='caption'>
                {translate("resources.pages.fields.helper_title_image")}
              </Typography>
              <ImageSelect
                imgSize={{ imgWidth: "150px", imgHeight: "50px" }}
                handleSelected={(value) =>
                  handleChangeField(value, "title_image")
                }
                componentData={componentData}
                hiddeLabel={true}
                field='title_image'
              />
            </Box>

            {listCategories.length > 0 ? (
              <SelectCategories
                listCategories={listCategories}
                value={componentData.category || ""}
                handleChangeField={handleChangeField}
              />
            ) : (
              <div className={classes.selectInput}>
                <LinearProgress />
              </div>
            )}
          </Box>
          <div className={classes.selectNotes}>
            <Typography className={classes.heading}>{translate("components.notes")}</Typography>
            {listNotes && (
              <ListDnd
                listNotes={listNotes}
                handleChangeListNote={handleChangeListNote}
                showImg={false}
              />
            )}
          </div>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <FormControlLabel
            control={
              <Switch
                checked={
                  componentData.show_copete === undefined
                    ? false
                    : !!componentData.show_copete || false
                }
                onChange={(e) =>
                  handleChangeField(e.target.checked, "show_copete")
                }
                color='primary'
              />
            }
            label={translate("resources.pages.fields.show_section_copete")}
            labelPlacement='start'
          />
        </Box>
      </Box>
    )
  );
};

export default GrillaFormWithoutImg;
