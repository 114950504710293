import { fetchUtils } from 'react-admin';

export const apiUrl = process.env.REACT_APP_API_HOST_ADMIN;
export const apiUrlPlanA = process.env.REACT_APP_API_HOST_PLAN_A;
export const apiCODPlanA = process.env.REACT_APP_API_COD_CLUB_PLAN_A || 'CLE';

const buidRequestOptions = (options) => {
    const token = localStorage.getItem('token');
    const customHeaders = options.headers ? options.headers : {},
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        optionsHeaders = { ...headers, ...customHeaders };

    options.headers = optionsHeaders;
    options.mode = 'cors'

    return options
}

export const httpClient = (url, options = {}) => {
    options = buidRequestOptions(options)
    options.headers = new Headers(options.headers)
    return fetchUtils.fetchJson(url, options);
};

export const sendAsFormData = (method, resource, params) => {
    const formData = new FormData();
    for (const name in params.data) {
        formData.append(name, params.data[name] ? params.data[name] : '');
    }

    if (resource === 'media') {
        if (params.data.media_type === 'image') {
            params.data.picture?.forEach(file => {
                formData.append('images[]', file.rawFile)
            });
        }
        if (params.data.media_type === 'file') {
            formData.set('file', params.data.file.rawFile)
        }
        // manually set the input file
    }
    if (resource === 'newspapers') {
        if (params.data.thumbnail) {
            formData.set('thumbnail', params.data.thumbnail.rawFile)
        }
        if (params.data.file) {
            formData.set('file', params.data.file.rawFile)
        }
    }

    if ((resource === 'authors' || resource === 'journalists' || resource === 'subscribers') && params.data.avatar_file) {
        formData.set('avatar_file', params.data.avatar_file.rawFile)
    }

    if (method === 'PUT') {
        formData.append('_method', 'PUT')
        resource = `${resource}/${params.id}`;
        method = 'POST';
    }

    const options = buidRequestOptions({
        ...params,
        method: method,
        body: formData,
    })
    delete options['data'];
    delete options.headers['Content-Type'];

    return fetch(`${apiUrl}/${resource}`, options)
        .then(async res => {
            if (res.status >= 200 && res.status < 300) {
                return res.json()
            }
            throw await res.json()
        })
        .then(json => {
            return ({
                data: { ...params.data, id: json.id, response: json },
            })
        })
        .catch(error => {
            let message = "";
            if (error.error && typeof error.error === 'object') {
                for (const [key, value] of Object.entries(error.error)) {
                    if (Array.isArray(value)) {
                        message += (` ${value.join(', ')}`);
                    } else {
                        message += (` ${value}`);
                    }
                }
            }
           throw new Error(message ? `Error: ${message}` : 'ra.message.error');
        });
};
