const catalogListTheme01 = [
  {
    name: "Bomba",
    key: "Bomba",
    settings: {
      background: "",
      fontcolor: "",
      title: "",
      show_copete: "",
      notes: [{ note: "" }],
    },
  },
  {
    name: "Grilla x3",
    key: "GrillaX3",
    settings: {
      background: "",
      fontcolor: "",
      title: "",
      title_image: "",
      category: {
        categories: [""],
        exclude: [],
      },
      notes: [{ note: "" }, { note: "" }, { note: "" }],
      show_tags: true,
    },
  },
  {
    name: "Grilla x6",
    key: "GrillaX6",
    settings: {
      background: "",
      fontcolor: "",
      title: "",
      title_image: "",
      category: {
        categories: [""],
        exclude: [],
      },
      notes: [
        { note: "" },
        { note: "" },
        { note: "" },
        { note: "" },
        { note: "" },
        { note: "" },
      ],
      show_tags: true,
    },
  },
  {
    name: "Grilla x4 - Sin imágenes",
    key: "GrillaX4WithoutImg",
    settings: {
      title: "",
      title_image: "",
      category: {
        categories: [""],
        exclude: [],
      },
      notes: [{ note: "" }, { note: "" }, { note: "" }, { note: "" }],
      show_copete: true,
      show_date_publish: true, // agrega la fecha al response
    },
  },
  {
    name: "Imagen Hero",
    key: "Hero",
    settings: {
      height: "medium",
      notes: [{ note: "" }],
      position_hero: "center",
      category: {
        categories: [""],
        exclude: [],
      },
    },
  },
  {
    name: "Publicidad",
    key: "Publicidad",
    settings: {
      image: "",
      url: "",
      type: "large-h100",
      position: "center",
      size: "none",
    },
  },
  {
    name: "Grilla Publicidad x 4",
    key: "Publicidadx4",
    settings: {
      type: "box-300",
      list: [
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
      ],
    },
  },
  {
    name: "Imagen + 2 Notas",
    key: "ImagenNotas",
    settings: {
      title: "",
      title_image: "",
      background: "",
      fontcolor: "",
      category: {
        categories: [""],
        exclude: [],
      },
      notes: [{ note: "" }, { note: "" }],
      list: [
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
      ],
      show_tags: true,
    },
  },
  {
    name: "Noticias por WhatsApp",
    key: "WhatsApp",
    settings: {
      title: "",
      phoneNumber: "",
      text: "",
      btnTxt: "",
      mode: "chat",
    },
  },
  {
    name: "Banner Suscripción",
    key: "SubscribeCTA",
    settings: {
      title: "Suscribite al periodismo de tu Ciudad",
      text: "Accedé a las notas completas y exclusivas",
      showIcon: true,
    },
  },
  {
    name: "Iframe Ancho completo",
    key: "IframeFullWidth",
    settings: {
      src: "",
      height: "",
    },
  },
];

const cataloListTheme03 = [
  {
    name: "Bomba",
    key: "Bomba",
    settings: {
      background: "",
      fontcolor: "",
      title: "",
      show_copete: "",
      notes: [{ note: "" }],
    },
  },
  {
    name: "Imagen + 2 Notas",
    key: "ImagenNotas",
    settings: {
      title: "",
      title_image: "",
      background: "",
      fontcolor: "",
      category: {
        categories: [""],
        exclude: [],
      },
      notes: [{ note: "" }, { note: "" }],
      list: [
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
      ],
      show_tags: true,
    },
  },
  {
    name: "Grilla x3",
    key: "GrillaX3",
    settings: {
      background: "",
      fontcolor: "",
      title: "",
      title_image: "",
      category: {
        categories: [""],
        exclude: [],
      },
      notes: [{ note: "" }, { note: "" }, { note: "" }],
      show_tags: true,
    },
  },
  {
    name: "Grilla x4",
    key: "GrillaX4",
    settings: {
      background: "",
      fontcolor: "",
      title: "",
      title_image: "",
      category: {
        categories: [""],
        exclude: [],
      },
      notes: [{ note: "" }, { note: "" }, { note: "" }, { note: "" }],
      show_tags: true,
    },
  },
  {
    name: "Grilla x6",
    key: "GrillaX6",
    settings: {
      background: "",
      fontcolor: "",
      title: "",
      title_image: "",
      category: {
        categories: [""],
        exclude: [],
      },
      notes: [
        { note: "" },
        { note: "" },
        { note: "" },
        { note: "" },
        { note: "" },
        { note: "" },
      ],
      show_tags: true,
    },
  },
  {
    name: "Grilla x4 - Sin imágenes",
    key: "GrillaX4WithoutImg",
    settings: {
      title: "",
      title_image: "",
      category: {
        categories: [""],
        exclude: [],
      },
      notes: [{ note: "" }, { note: "" }, { note: "" }, { note: "" }],
      show_copete: true,
      show_date_publish: true, // agrega la fecha al response
    },
  },
  {
    name: "Imagen Hero",
    key: "Hero",
    settings: {
      height: "medium",
      notes: [{ note: "" }],
      position_hero: "center",
      category: {
        categories: [""],
        exclude: [],
      },
    },
  },
  {
    name: "Noticias por WhatsApp",
    key: "WhatsApp",
    settings: {
      title: "",
      subtitle: "",
      phoneNumber: "",
      text: "",
      btnTxt: "",
      mode: "chat",
    },
  },
  {
    name: "Banner Suscripción",
    key: "SubscribeCTA",
    settings: {
      title: "Suscribite al periodismo de tu Ciudad",
      text: "Accedé a las notas completas y exclusivas",
      showIcon: true,
    },
  },
  {
    name: "Publicidad",
    key: "Publicidad",
    settings: {
      image: "",
      url: "",
      type: "large-h100",
      position: "center",
      size: "none",
    },
  },
  {
    name: "Grilla Publicidad x 4",
    key: "Publicidadx4",
    settings: {
      type: "box-300",
      list: [
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
        {
          image: "",
          url: "",
          position: "center",
          size: "none",
        },
      ],
    },
  },
  {
    name: "Iframe Ancho completo",
    key: "IframeFullWidth",
    settings: {
      src: "",
      height: "",
    },
  },
];

export const catalogList =
  process.env.REACT_APP_THEME === "theme03"
    ? cataloListTheme03
    : catalogListTheme01;
