import * as React from "react";
import { Edit, SimpleForm, TextInput, NumberInput, DateInput, BooleanInput, required } from "react-admin";
import ImagePicker from "../../components/pickers/ImagePicker";
import { ImageField } from "./ImageField";

export const PollsEdit = (props) => (
  <Edit title=" " {...props}>
    <SimpleForm>
      <ImagePicker />
      <DateInput source="date_question" validate={[required()]} />
      <TextInput source="question" fullWidth validate={[required()]} />
      <ImageField source="media_id" resource="polls" />
      <TextInput source="option_1" fullWidth validate={[required()]} />
      <NumberInput source="selected_1" />
      <TextInput source="option_2" fullWidth validate={[required()]} />
      <NumberInput source="selected_2" />
      <TextInput source="option_3" fullWidth />
      <NumberInput source="selected_3" />
      <TextInput source="option_4" fullWidth />
      <NumberInput source="selected_4" />
      <TextInput source="option_5" fullWidth />
      <NumberInput source="selected_5" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);
